// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-cloud-js": () => import("../src/pages/cloud.js" /* webpackChunkName: "component---src-pages-cloud-js" */),
  "component---src-pages-contacts-js": () => import("../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-containers-js": () => import("../src/pages/containers.js" /* webpackChunkName: "component---src-pages-containers-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-servers-js": () => import("../src/pages/servers.js" /* webpackChunkName: "component---src-pages-servers-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-virtual-machines-js": () => import("../src/pages/virtual-machines.js" /* webpackChunkName: "component---src-pages-virtual-machines-js" */)
}

