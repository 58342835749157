module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#E6E6E6","theme_color":"#E6E6E6","display":"minimal-ui","icon":"src/images/favicon.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"YOUR_GOOGLE_ANALYTICS_TRACKING_ID","anonymize":true},"facebookPixel":{"pixelId":"YOUR_FACEBOOK_PIXEL_ID"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/srv/web/veks/src/intl","languages":["en","pt"],"defaultLanguage":"pt","redirect":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
